import Environment from "../components/Impact/Environment";
import Healthcare from "../components/Impact/Healthcare";
import ImpactBanner from "../components/Impact/ImpactBanner";
import Standards from "../components/Impact/Standards";
//import Stories from "../components/Impact/Stories";
import Footer from "./Footer";
import Navigation from "./Navigation";
function Impact() {
  return (
    <div>
      <Navigation />
      <ImpactBanner />
      <Healthcare />
      <Environment />
      <Standards />
      <Footer />
    </div>
  );
}
export default Impact;
//<Stories />