import { Col, Container, Row } from "react-bootstrap";

function Services() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FAFAFA",
        width: "100%",
      }}
    >
      <Container className="pt-2 mt-2 pb-5">
        <Row className="mt-5">
          <Col className="mt-5 mb-5" lg={5} md={12}>
            <h1 className="pt-5 pb-1 sub-title" style={{ fontWeight: 700 }}>
              Our Services
            </h1>
            <h6 className="w-75">
              Kriya’s digital platform {" "}
              <span style={{ fontWeight: "700" }}>“one-click” </span>{" "}
              provides a suite of solutions to automate laboratory and at home collection workflow, train technicians, and integrate laboratory systems to ensure improved diagnostics outcomes.
            </h6>
          </Col>
          <Col lg={7} md={12} className="mb-5">
            <img
              src="/images/homepage/one_click.png"
              alt="laptop"
              width="100%"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Services;
