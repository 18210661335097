//import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap"; //Button,
//import { AiOutlineArrowRight } from "react-icons/ai";

function Trivus() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#D5D4D2",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row className="pt-5 mt-5 pb-5">
          <Col>
            <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700 }}>
              Introducing KRIVIDA Trivus
            </h1>
            <h6 className="w-75 para" style={{
              color: "#903D81", fontWeight: 500
            }}>
              India’s first ICMR approved affordable RT-qPCR kit to detect influenza, SARS-CoV-2 and RSV
            </h6>
            <div>
              {" "}
              <h6 className="w-75 para">
                {" "}
                Kriya’s latest product introduction is based on the science of how the pandemic has evolved and also the current need of the hour. An affordable, ICMR approved multiplex kit that can detect the presence or absence of Respiratory Syncytial Virus (RSV), Influenza A and B and SARS CoV2 with a single tube. The RT-qPCR Trivus ResPi kit is an open kit that can be used on any RT PCR equipment and has the shortest cycle time of 27 minutes. It will be key not only as a surveillance tool but also as a decision support tool for clinicians in deciding on the right kind of treatment.
              </h6>
              <br />
              <div><div className="banner-button-invert" style={{ width: "302px", backgroundColor: "#903D81" }} onClick={
                () => { window.location.href = "/trivus" }
              }><div className="w-100 text-center">Know More</div></div></div>
              <div className="banner-button-holder">
                <div><div className="banner-button-invert" onClick={() => { window.open("/catalogues/FAQ_TRIVUS.pdf"); }}><div className="w-100 text-center">FAQ</div></div></div>
                <div><div className="banner-button-invert" onClick={() => { window.open("/catalogues/IFU_KRIVIDA_Trivus.pdf"); }}><div className="w-100 text-center">IFU</div></div></div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <img
              src="/images/homepage/trivus.jpg"
              alt="krivida"
              width="100%"
              className="img-fluid mt-4"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Trivus;
