import { Col, Container, Row } from "react-bootstrap";

function BiosampleBanner() {
  const scroll = () => {
    const section = document.querySelector('#scroll-me-biosample');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <Container className="mobile-padding position-relative mb-5">
      <Row>
        <Col lg={6} md={12} className="mt-5 pt-5">
          <h1 className="banner-title" style={{ fontWeight: 700 }}>
            Biosample Collection
          </h1>
          <p className="para">
            Kriya has an innovative portfolio of biosample collection and preparation products. Manufactured with state-of-the-art technology, the products revolutionise the collection of blood and other body fluids. They also support the preanalytical process by incorporating traceability and accuracy in the workflow.<br />
            <br />
            Our suite of products eliminates pre-analytical errors and reduce costs and turnaround time, thereby ensuring better treatment outcomes for all.
          </p>
          <div><div className="banner-button" onClick={() => { scroll() }}><div>Know More</div></div></div>
        </Col>

        <Col lg={6} md={12} className="text-left">
          <img
            className="mt-3 img-fluid"
            src="/images/biosample/banner.png"
            alt="hand"
            height="auto"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default BiosampleBanner;
