import { Container } from "react-bootstrap";

function AboutBanner() {
  return (
    <Container className="mobile-padding position-relative mb-5">
      <div className="about-banner-card">
        <div className="about-banner-info-layer mt-5 pt-5">
          <h1 className="banner-title">Our Journey of Growth</h1>
          <span className="para">Learn what drives our purpose, vision, and values.</span>
        </div>
        <div className="about-banner-img-layer">
          <img
            className="mt-3 img-fluid"
            src="/images/about/hands.png"
            width="673px"
            alt="hand"
            height="auto"
          />
        </div>
      </div>
    </Container>
  );
}

export default AboutBanner;
