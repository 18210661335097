import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
/**
 <span style={{ fontWeight: 700 }}>
                    The devices’ most notable features include:
                  </span>
                </p>{" "}
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        100% sensitivity, specificity, and reproducibility
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Eliminating the need for cold chain transport by
                        maintaining specimen integrity until processing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Decreased turnaround time and overall cost by 40% and
                        50%, respectively, through an extraction-free process
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Guaranteed technician safety with minimal risk of
                        infections
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Ergonomically designed saliva collection funnel to
                        prevent reverse flow and ensure patient safety
                      </p>
                    </div>
                  </Col>
                </Row>
 */
function MCollection() {
  const [active, setActive] = useState("first");
  return (
    <>
      <style type="text/css">
        {`
    .div-hover {
     
    }
    .div-hover:hover {
      border: 1px solid #000
      background-color: #FFFFFF;
      cursor: pointer;
      border-radius: 10px;
    }
    .div-active {
      background-color: #FFFFFF;
      box-shadow : 1px 1px 1px #dfdfdf;
      border-radius: 10px;
    }
    .div-samples {
      border-bottom: 5px #c5ecee solid;
      cursor: pointer;
      
    }
    .div-samples:hover {
      color: #d73b8b;
      border-bottom: none;
    }
    .btn-pink-3 {
      background-color: #d73b8b;
      color: white;
      
    }
    .btn-pink-3:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: white;
    }
    .btn-white-1 {
      background-color: white;
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    .btn-white-1:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    `}
      </style>
      <div className="mobile-padding"
        style={{
          background: "#FAFAFA",
          width: "100%"
        }}
        id="scroll-me-molecular"
      >
        <Container className="pt-5">
          <h4 className="pt-5 sub-title" style={{ fontWeight: 700 }}>
            Molecular Diagnostics product range includes:
          </h4>
          <Row className="pt-5 d-flex flex-sm-column flex-lg-row">
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("first");
                }}
                className={
                  active === "first"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/covid_kit.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p className="para" style={{ color: "#2b2b2b", fontWeight: 400, padding: "10px" }}>
                  KRIVIDA<sup>TM</sup> Novus Five Gene Omicron Detection Kit
                </p>
              </div>
            </Col>
          </Row>
          {active === "first" && (
            <>
              <div>
                <h1
                  className="sub-title w-sm-100 w-lg-75 m-auto pt-5"
                  style={{ fontWeight: 400 }}
                >
                  KRIVIDA<sup>TM</sup> Novus Five Gene Omicron Detection Kit
                </h1>
                <p className="pt-4 para">
                  Kriya’s real-time PCR testing kit leverages cutting-edge technology for detecting SARS-CoV-2  and differentiating the strain contracted in a single test.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Groundbreaking genome sequencing technique for diagnosing Omicron and Deltacron with the ability to also detect newer emerging variants
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Test runtime of only 45 minutes
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Compatibility with all real-time PCR equipment
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Fast and accurate entry point screening at airports, bus stations, schools, malls etc.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Thin-walled medical stainless steel material to enable faster insulin flow rates
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIVIDA NOVUS KIT
                    </h6>
                    <img
                      src="/images/biosample/mol_1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {active === "second" && (
            <>
              <div>
                <h1
                  className="w-50 text-center m-auto pt-5"
                  style={{ fontWeight: 400 }}
                >
                  Urine Collection System
                </h1>
                <p className="text-center pt-4">
                  Our safe urine collection system is ideal for the easy and convenient extraction of urine samples. It uses a special, calibrated vacuum technology to ensure accuracy of specimen collection while maintaining complete sterility of the samples.
                  <br />
                  <br />
                </p>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%",
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2>Applications</h2>
                      <p>
                        <ul>
                          <li>  	Routine urine analysis such as urine proteins, glucose, electrolytes, specific gravity </li>
                          <li>  	Urine culture and sensitivity </li>
                          <li>  	Urine light microscopy for microorganisms, casts, crystals</li>
                          <li>  	Urine antigen testing for screening of Gonorrhoea, Chlamydia, Legionella, Mycoplasma</li>
                          <li> 	Specialised tests including urine metanephrines, ketone bodies, drug screening, osmolarity, osmolality</li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>

            </>
          )}
          {active === "third" && (
            <>
              <div>
                <h1
                  className="w-50 text-center m-auto pt-5"
                  style={{ fontWeight: 400 }}
                >
                  Blood Collection Accessories
                </h1>
                <p className="text-center pt-4">
                  Our products are compatible with Kriya’s complete range of biosample offerings. Kriya’s line of safety-engineered blood collection accessories prevents accidental cross-contamination and needlestick injuries to ensure efficient, hassle-free sample handling.
                  <br />
                  <br />
                </p>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%",
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2>Applications</h2>
                      <p>
                        <ul>
                          <li>Routine blood collection and venepuncture</li>
                          <li>Disinfection of sample sites prior to blood collection and point-of-care testing</li>
                          <li>Disposal of medical sharps</li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "fourth" && (
            <>
              <div>
                <h1
                  className="w-50 text-center m-auto pt-5"
                  style={{ fontWeight: 400 }}
                >
                  Swab and Saliva-Based Sample Collection Devices
                </h1>
                <p className="text-center pt-4">
                  Our range of molecular transport media is ideal for easy, safe
                  collection and transport of saliva and oral samples. We also
                  ensure efficient RNA and DNA sequencing and transport for
                  nasopharyngeal samples. All our products are compatible with
                  various instruments, extraction kits, and PCR amplification
                  kits.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The devices’ most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        100% sensitivity, specificity, and reproducibility
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Eliminating the need for cold chain transport by
                        maintaining specimen integrity until processing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Decreased turnaround time and overall cost by 40% and
                        50%, respectively, through an extraction-free process
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Guaranteed technician safety with minimal risk of
                        infections
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Ergonomically designed saliva collection funnel to
                        prevent reverse flow and ensure patient safety
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1cs",
                  width: "100%",
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2>Applications</h2>
                      <h6>Collection and transport of specimens for:</h6>
                      <p>
                        • RNA sequencing for the detection of SARS-CoV-2,
                        influenza, and HPV
                        <br />• DNA sequencing for genomic studies
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "fifth" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 text-center m-auto pt-5"
                  style={{ fontWeight: 400 }}
                >
                  Smart Specimen Transportation System
                </h1>
                <p className="text-center pt-4">
                  Our Bluetooth-enabled cold-chain bags are ideal for the safe, secure, everyday transport of specimens. They are IoMT integrated, thereby allowing real-time temperature and location monitoring of the samples.
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The system’s most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Multi-chambered design with storage capacity of up to 200 blood samples
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Triple packaging solution to maintain temperature between 2°- 8°C, up to 10 hours
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Shock-proof, leak-proof, and water-resistant
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Ergonomically designed for comfort
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>

            </>
          )}
          <Row className=" pt-5 mb-5 pb-5">
            <Col />
            <Col sm={12} lg={3} className="text-center pb-3">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("/catalogues/Kriya_Catalogue.pdf"); }
              }><div>Download Catalogue</div></div></div>
            </Col>
            <Col sm={12} lg={3} className="text-center">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("mailto:bdadmin@kriyamed.com"); }
              }><div>Request More Details</div></div></div>
            </Col>
            <Col />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MCollection;
