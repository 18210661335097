import { Container } from "react-bootstrap"; //Col, Row, 
//className="margin-about-us-title"
/*
   <h3 className="pt-3 sub-title" style={{ color: "#F7CA8E", fontWeight: 700 }}>
                Kriya Medical Technologies
              </h3>
            
*/
function About() {
  return (
    <div className="mobile-padding" style={{ background: "#1c1c1c", paddingTop: "120px", paddingBottom: "114px" }} id="scroll-me">
      <Container className="position-relative ">
        <div className="home-about" style={{ background: "#1c1c1c", marginRight: "0px" }}>
          <div  >
            <div className="pb-1">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700, color: "#FFFFFF" }}>
                Kriya’s Mission
              </h1>
            </div>
            <div className="">
              <p className="para" style={{ color: "#F7CA8E", fontWeight: 400 }}>
                To transform the detection and treatment of infectious diseases using data-led diagnostic solutions impacting lives across the globe, every single day.
              </p>
            </div>
          </div>
          <div className="py-3 margin-about-us">
            <p className="para" style={{ color: "#FFFFFF" }}>
              We strive to advance diagnostics and improve outcomes through our innovative medical devices and end-to-end digital solutions.
            </p>
            <p className="para" style={{ color: "#FFFFFF" }}>
              All our medical devices are designed with built-in quality and manufactured in state-of-the-art facility to ensure accuracy and high standards.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

/**
           <p className="para" style={{ color: "#FFFFFF" }} >
              Kriya’s mission is to transform the detection and treatment of infectious diseases by way of data-led diagnostics and medical devices. Since 2012, our end-to-end solutions and data-driving insights have advanced diagnostics and improved outcomes.
            </p>
            <p className="para" style={{ color: "#FFFFFF" }}>
              Through deep partnerships, diverse perspectives, innovative devices, and digital solutions, Kriya continues to build connections across the continuum of diagnostics.
            </p>
            <p className="para" style={{ color: "#FFFFFF" }}>
              Using novel technologies, state-of-the-art manufacturing facilities, and a quality-by-design approach, Kriya is proud to deliver world-class diagnostics impacting lives globally, every single day.
            </p>
  
 */

export default About;
