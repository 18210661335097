import { Col, Container, Row } from "react-bootstrap";

function Standards() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FAFAFA",
        width: "100%"
      }}
    >
      <Container>
        <Row>
          <Col md={12} sm={12}>
            <h1
              className="mt-5 pb-1 sub-title"
              style={{ fontWeight: 700 }}
            >
              Holding ourselves to the highest standards
            </h1>
            <p className="para">
              We strictly follow internationally approved standards to design products and operate facilities. Our proprietary Quality Operating System (K-QOS) complies with{" "}
              <span style={{ color: "#ad4594", fontWeight: 700 }}>
                {" "}ISO 13485:2016{" "}
              </span>
              QMS requirements and accepted GMP standards. Our facilities meet both US and Indian FDA guidelines.<br />
              <br />
              All our products are designed and validated by our stringent product requirement protocols and are registered with the Indian government FDA. They are also compliant for export and free sale. Furthermore, Kriya is also certified to operate with a drug license.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={0} />
          <Col>
            <img
              src="/images/impact/ISO9001.png"
              alt="iso"
              className="img-fluid"
            />
          </Col>
          <Col md={2} sm={0} />
        </Row>
      </Container>
    </div>
  );
}

export default Standards;
