import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Homepage from "./routes/Homepage";
import Company from "./routes/Company";
import Products from "./routes/Products";
import Impact from "./routes/Impact";
import RandD from "./routes/RandD";
import { Fragment } from "react";
import TrivusDetail from "./routes/TrivusDetail";
//import Career from "./routes/Career";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="company" element={<Company />} />
        <Route path="products/biosample" element={<Products name="biosample" />} />
        <Route path="products/molecular" element={<Products name="molecular" />} />
        <Route path="products/diabetes" element={<Products name="diabetes" />} />
        <Route path="impact" element={<Impact />} />
        <Route path="r_d" element={<RandD />} />
        <Route path="trivus" element={<TrivusDetail />} />
      </Routes>
    </Fragment>
  );
}
//<Route path="career" element={<Career />} />
export default App;
