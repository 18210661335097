import { Col, Container, Row } from "react-bootstrap";

function Global() {
  return (
    <>
      <Container className="mobile-padding mt-5 mb-5 text-center">
        <Row>
          <Col>
            <div className="mb-5">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700 }}>
                Our Global Footprint
              </h1>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_patien.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              70M
            </div>
            <p className="img-sub-title">
              Patient lives <br /> touched
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_pathlogy.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              300M
            </div>
            <p className="img-sub-title">
              Pathology tests <br /> enabled
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_covid.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              3000+
            </div>
            <p className="img-sub-title">
              National Lab Collection Centers Supplied
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_device_manufacture.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              360M
            </div>
            <p className="img-sub-title">
              Devices manufactured <br /> annually
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Global;
//<img src="/images/homepage/3M.png" alt="60M" width={71} height={40} />