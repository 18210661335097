import { Col, Container, Row } from "react-bootstrap";
/**
 <Col lg={4} md={4} sm={4} xs={4}>
            <h1 className="pt-5 pb-5" style={{ fontWeight: 700 }}>
              In the Media
            </h1>
          </Col>
 */
//"bg1-white-black" //"bg1-white" //align-media-center
//<span className="logo-action-sub-title">In the media</span>
//className="logo-action-app-link"


function TheMedia() {
  return (
    <div className="my-5">
      <Container className="">
        <Row className="text-center justify-items-center">
          <Col className="text-center">
            <h1 className="pt-3 sub-title">
              In the Media
            </h1>
          </Col>
        </Row>
        <Row className="mob-media-center justify-items-center">
          <Col lg={3} className="mob-media-center">
            <a href="https://www.thehindu.com/news/national/kriya-medical-tech-gets-icmr-approval-for-covid-19-testing-kit/article38296422.ece" target="_blank" rel="noreferrer">
              <img
                src="/images/homepage/hin_logo.png"
                alt="et"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Col>
          <Col lg={3} className="mob-media-center">
            <a href="https://www.financialexpress.com/healthcare/kriya-medical-technologies-gets-dcgi-nod-for-rt-pcr-kit/2433300/" target="_blank" rel="noreferrer">
              <img
                src="/images/homepage/fin_logo.png"
                alt="st"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TheMedia;
