import { Col, Container, Row } from "react-bootstrap";

function TrivusContent() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FAFAFA",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row>
          <Col md={12} sm={12}>
            <h1
              className="pt-5 mt-5 pb-1 banner-title"
              style={{ fontWeight: 700 }}
            >
              Welcome to KrividaTM Trivus Respi Panel RT-qPCR Kit: Revolutionizing Respiratory Tract Infection Diagnosis
            </h1>
            <p className="para">
              Unlock a new era in respiratory infection diagnostics with KrividaTM Trivus Respi Panel RT-qPCR Kit. Our cutting-edge diagnostic solution is meticulously crafted to transform the way healthcare professionals approach respiratory tract infections, setting unprecedented standards in accuracy, efficiency, and economic viability.
            </p>
            <h1
              className="pb-1 sub-title"
              style={{ fontWeight: 700 }}
            >
              Pioneering Diagnostic Excellence:
            </h1>
            <p className="para">
              The Krivida Trivus Respi Panel RT-qPCR Kit is a testament to innovation, approved by the CDSCO for manufacturing. It redefines diagnostic accuracy and efficiency, catering to the complex nature of respiratory tract infections.
            </p>
            <h2 className="sub-title">Key Features:</h2>
            <h6 className="para" style={{ fontWeight: 600 }}>Simultaneous Detection of Major Viruses:</h6>
            <p className="para">
              <ul>
                <li>RSV, Influenza A & B, and SARS-CoV-2 are detected concurrently, providing a comprehensive view of the patient’s viral landscape.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Top 3 Causes Identification:</h6>
            <p className="para">
              <ul>
                <li>Krivida Trivus Respi Panel is the first-of-its-kind kit identifying the top three causes of viral pneumonia, setting a new standard in respiratory infection diagnostics.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Rapid Turnaround Time:</h6>
            <p className="para">
              <ul>
                <li>Time is of the essence in healthcare. Our kit boasts a rapid turnaround time of less than 1 hour, empowering healthcare professionals with timely and actionable results.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Instrument Compatibility:</h6>
            <p className="para">
              <ul>
                <li>Seamlessly integrate our kit into your existing diagnostic infrastructure, as it is compatible with all available RTPCR instruments.</li>
              </ul>
            </p>
            <h2 className="sub-title">Disease-Specific Insights:</h2>
            <h6 className="para" style={{ fontWeight: 600 }}>RSV Detection:</h6>
            <p className="para">
              <ul>
                <li>The most common cause of viral pneumonia in children under 5.</li>
                <li>A leading cause of viral pneumonia-related hospitalization in pediatric and geriatric populations.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Influenza Detection:</h6>
            <p className="para">
              <ul>
                <li>Timely identification enables effective treatment with Oseltamivir within 3 days of infection.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Coinfection Detection:</h6>
            <p className="para">
              <ul>
                <li> Identify coinfections for a more nuanced understanding of the patient’s condition, aiding prognosis and treatment decisions.</li>
              </ul>
            </p>
            <h2 className="sub-title">Economic Advantages:</h2>
            <h6 className="para" style={{ fontWeight: 600 }}>Cost-Efficient Testing:</h6>
            <p className="para">
              <ul>
                <li>Experience a remarkable 10x reduction in cumulative testing costs compared to individual tests.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Consumable Savings:</h6>
            <p className="para">
              <ul>
                <li>Realize a 3x reduction in consumable usage, contributing to significant cost savings without compromising quality.</li>
              </ul>
            </p>
            <h6 className="para" style={{ fontWeight: 600 }}>Swift Decision-Making:</h6>
            <p className="para">
              <ul>
                <li>Benefit from a 3x reduction in turnaround time, facilitating quicker decision-making and intervention.</li>
              </ul>
            </p>
            <h2 className="sub-title">ICMR Validated Assurance:</h2>
            <p className="para">
              Rest assured, Krivida Trivus Respi Panel RT-qPCR Kit is ICMR validated, attesting to its adherence to the highest standards of quality, reliability, and accuracy.
            </p>
          </Col>
        </Row>
        <Row className=" pt-5 mb-5 pb-5" s>
          <Col />
          <Col sm={12} lg={3} className="text-center pb-3">
            <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
              () => { window.open("/catalogues/Trivus_Brochure.pdf"); }
            }><div>Download Catalogue</div></div></div>
          </Col>
          <Col sm={12} lg={3} className="text-center">
            <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
              () => { window.open("mailto:riby@kriyamed.com"); }
            }><div>Request a Call Back</div></div></div>
          </Col>
          <Col />
        </Row>
      </Container>
    </div>
  );
}

export default TrivusContent;
