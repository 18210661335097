import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

/*
<h6 className="w-sm-100 w-lg-75 para">
                Our digital{" "}
                <span style={{ fontWeight: "bolder" }}>“one-click” </span>{" "}
                platforms across the continuum of technicians and laboratory
                systems improve diagnostic outcomes.
              </h6>
 */

function GuidingLights() {
  const [active, setActive] = useState("first");
  return (
    <>
      <style type="text/css">
        {`
    .btn-purple-1 {
      background-color: #ad4594;
    
      color: white;
    }
    .btn-purple-1:hover {
      background-color: #ad4594;
      color: white;
    }
    .btn-teal-1 {
      background-color: #79bdb2;
    
      color: white;
    }
    .btn-teal-1:hover {
      background-color: #79bdb2;
      color: white;
    }
    .btn-pink-1 {
      background-color: #d83b8c;
    
      color: white;
    }
    .btn-pink-1:hover {
      background-color: #d83b8c;
      color: white;
    }
    .btn-gold-1 {
      background-color: #dab176;
    
      color: white;
    }
    .btn-gold-1:hover {
      background-color: #dab176;
      color: white;
    }
    
    `}
      </style>
      <div className="mobile-padding"
        style={{
          backgroundColor: "#fff",
          width: "100%"
        }}
      >
        <Container className="">
          <Row className="pt-5 pb-5">
            <Col className="mt-5">
              <h1 className="w-sm-100 w-lg-75 sub-title" style={{ fontWeight: 700 }}>
                The four guiding lights that lead us
              </h1>
              {active === "first" && (
                <>
                  <div className="w-75 pt-4">
                    <h4
                      className=" p-2 text-center para"
                      style={{
                        width: "200px",
                        background: "#79bdb2",
                        color: "white",
                        letterSpacing: "3px",
                      }}
                    >
                      INNOVATION
                    </h4>
                    <h6
                      className="w-sm-100 w-lg-75 pt-2 para"
                      style={{ color: "2b2b2b" }}
                    >
                      Good health for all is possible only if healthcare is affordable. This calls for a noble quest to challenge and change the present. We strive to look at problems through the lens of creativity based on the strong foundation of knowledge.
                    </h6>
                  </div>
                </>
              )}
              {active === "second" && (
                <>
                  <div className="w-75 pt-4">
                    <h4
                      className=" p-2 text-center para"
                      style={{
                        width: "200px",
                        background: "#d83b8c",
                        color: "white",
                        letterSpacing: "3px",
                      }}
                    >
                      INCLUSIVITY
                    </h4>
                    <h6
                      className="w-sm-100 w-lg-75 pt-2 para"
                      style={{ color: "#2b2b2b" }}
                    >
                      We believe that diversity fuels innovation – diversity of ideas and thoughts - everyone is equal and all ideas are welcome.
                    </h6>
                  </div>
                </>
              )}
              {active === "third" && (
                <>
                  <div className="w-75 pt-4">
                    <h4
                      className=" p-2 text-center para"
                      style={{
                        width: "200px",
                        background: "#ad4594",
                        color: "white",
                        letterSpacing: "3px",
                      }}
                    >
                      CURIOSITY
                    </h4>
                    <h6
                      className="w-sm-100 w-lg-75 pt-2 para"
                      style={{ color: "2b2b2b" }}
                    >
                      Verve, vigour, and resilience are the cornerstones of Kriya. We approach our work with pride, enthusiasm, and positivity.
                    </h6>
                  </div>
                </>
              )}
              {active === "fourth" && (
                <>
                  <div className="w-75 pt-4">
                    <h4
                      className=" p-2 text-center para"
                      style={{
                        width: "200px",
                        background: "#dab176",
                        color: "white",
                        letterSpacing: "3px",
                      }}
                    >
                      COMPASSION
                    </h4>
                    <h6
                      className="w-sm-100 w-lg-75 pt-2 para"
                      style={{ color: "2b2b2b" }}
                    >
                      We are constantly exploring different perspectives, and seeking real human connection. Empathy acts as our guiding light for our customers, partners, and employees, especially from our product ideation to delivery.
                    </h6>
                  </div>
                </>
              )}
            </Col>
            <Col
              lg={5}
              md={12}
              className="globe-guide"
            >
              <img
                src="/images/about/globus1.png"
                alt=""
                className="img-fluid position-absolute globus"
                style={{ zIndex: 1 }}
              />
              <Row className="para">
                <Col
                  className="position-relative overflow-hidden"
                  style={{ zIndex: 1 }}
                >
                  {" "}
                  <div style={{ marginTop: "46%", marginLeft: "40%" }}>
                    <Button
                      variant="teal-1"
                      onClick={() => {
                        setActive("first");
                      }}
                    >
                      INNOVATION
                    </Button>
                  </div>
                  <div style={{ marginTop: "6%", marginLeft: "68%" }}>
                    <Button
                      variant="pink-1"
                      onClick={() => {
                        setActive("second");
                      }}
                    >
                      INCLUSIVITY
                    </Button>
                  </div>
                  <div
                    style={{ marginTop: "6%", marginLeft: "13%" }}
                    onClick={() => {
                      setActive("third");
                    }}
                  >
                    <Button variant="purple-1">CURIOSITY</Button>
                  </div>
                  <div
                    style={{ marginTop: "6%", marginLeft: "51%" }}
                    onClick={() => {
                      setActive("fourth");
                    }}
                  >
                    <Button variant="gold-1">COMPASSION</Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GuidingLights;
