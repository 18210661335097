//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function Banner() {
  const scroll = () => {
    const section = document.querySelector('#scroll-me');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <Container className="mobile-padding position-relative ">
      <div className="banner-card">
        <div className="banner-info-layer">
          <h1 className="banner-title">Good Health For All</h1>
          <span className="banner-description">
            Where Innovation Meets Compassion for <br />
            Next-Gen Diagnostics
          </span>
          <div className="banner-button-holder">
            <div><div className="banner-button-invert" onClick={() => { window.open("mailto:bdadmin@kriyamed.com"); }}><div>Contact Us</div></div></div>
            <div><div className="banner-button" onClick={() => { scroll() }}><div>Know More</div></div></div>
          </div>
        </div>

        <div className="banner-img-layer">
          <img
            className="mt-3 position-absolute"
            src="/images/homepage/banner-01.png"
            alt="hand"
            width="554px"
            height="auto"
          />
        </div>
      </div>
    </Container>
  );
}
//
export default Banner;
