import { Col, Container, Row } from "react-bootstrap";

function DiabetesBanner() {
  const scroll = () => {
    const section = document.querySelector('#scroll-me-diabetes');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <Container className="mobile-padding position-relative">
      <Row>
        <Col lg={6} md={12} className="mt-5 pt-5">
          <h1 className="sub-title" style={{ fontWeight: 700 }}>
            Diabetes Care
          </h1>
          <p className="para">
            Kriya offers advanced care solutions for the complete monitoring and management of diabetes. Our point-of-care diagnostic devices enable easy and reliable, on-the-go testing while enhancing patient comfort.
          </p>
          <div><div className="banner-button" onClick={() => { scroll() }}><div>Know More</div></div></div>
        </Col>

        <Col lg={6} md={12} className="text-left">
          <img
            className="mt-3 img-fluid"
            src="/images/biosample/banner.png"
            alt="hand"
            height="auto"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default DiabetesBanner;
