//import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap"; //Button,
//import { AiOutlineArrowRight } from "react-icons/ai";
function Covid() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FAFAFA",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row className="pt-5 mt-5 pb-5">
          <Col>
            <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700 }}>
              Our COVID-19 Response
            </h1>
            <h6 className="w-75 para">
              Kriya rose to the challenge as the COVID-19 pandemic evolved with new variants emerging, by leveraging our in-house expertise to develop an innovative, safe, affordable and reliable solution for accurate variant identification.
            </h6>
            <div>
              {" "}
              <h6 className="w-75 para">
                {" "}
                Our KRIVIDA Novus SARS-CoV-2 Kit, a real-time PCR testing kit, can detect positive COVID-19 cases, differentiate the type of strain contracted, and identify new COVID-19 strains.
              </h6>
              <br />
              <div><div className="banner-button-invert" style={{ width: "142px" }} onClick={
                () => { window.location.href = "/products/molecular" }
              }><div>Read more</div></div></div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <img
              src="/images/homepage/krivida.png"
              alt="krivida"
              width="100%"
              className="img-fluid mt-4"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Covid;
