//import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap"; //Button, 
//import { AiOutlineArrowRight } from "react-icons/ai";

function Catalogue() {

  return (
    <div className="mobile-padding"
      style={{
        background: "#1c1c1c",
        width: "100%",
      }}
    >
      <Container>
        <Row className=" align-items-center align-content-center pt-5 pb-5">
          <Col lg={7} className="">
            <h1 className="font-weight-bold sub-title text-white">
              View Kriya’s Complete Product Portfolio
            </h1>
          </Col>
          <Col>
            <div className=""><div className="banner-button" style={{ width: "224px" }} onClick={
              () => { window.open("/catalogues/Kriya_Catalogue.pdf"); }
            }><div>Download Catalogue</div></div></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Catalogue;


/**
   const [isHoveringBackground, setIsHoveringBackground] = useState(false);

  const handleMouseEnterBackground = () => {
    setIsHoveringBackground(true);
  };

  const handleMouseLeaveBackground = () => {
    setIsHoveringBackground(false);
  };

  <a href="/catalogues/Kriya_Catalogue.pdf" target="_blank" rel="noreferrer">
    <Button
      variant="danger"
      style={{
        backgroundColor: !isHoveringBackground ? "#fff" : "#d73b8b",
        color: !isHoveringBackground ? "#2b2b2b" : "#fff",
        boxShadow: !isHoveringBackground
          ? ""
          : "0px 0px 60px 6px rgba(215,59,140,0.25)",
        border: "none",
        fontSize: "12px",
        fontWeight: 600,
        height: "52px",
        width: "254px",
        borderRadius: 0,
        letterSpacing: "1px",
      }}
      onMouseEnter={handleMouseEnterBackground}
      onMouseLeave={handleMouseLeaveBackground}
    >
      <AiOutlineArrowRight />
    </Button>{" "}
  </a>
 */