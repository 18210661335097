import { Col, Container, Row } from "react-bootstrap";

function Founder() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FFFFFF",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row className="pt-5 pb-5">
          <Col className="">
            <h1 className="pb-1 sub-title" style={{ fontWeight: 700 }}>
              A note from Founder
            </h1>
            <h6 className="w-sm-100 w-lg-75 para">
              The first 20 years of my career were spent as an Electrical Engineer on foreign assignment, living across four different continents. My observations of the affordability and accessibility healthcare gap between urban and rural communities culminated in the decision to pivot from the automotive industry to healthcare.<br /><br />
              I started KRIYA to bring practical medical solutions to rural communities in emerging markets so that they might receive the same level of care that my family and I have been lucky enough to experience throughout our lives.<br /><br />
              From the beginning, KRIYA has focused on applying frugal engineering methods to product design and manufacturing. We have innovated and indigenized an affordable “pre-analytical blood sample collection” system, which has extended access to healthcare for the underserved population in the rural areas of India. Over the years, KRIYA’s expertise has grown, resulting in a robust portfolio of sample collection medical devices.<br /><br />
              KRIYA’s life and mission changed in 2020 as we stood united on the front lines to help the world take on the COVID-19 pandemic. We streamlined our capabilities into the singular objective of creating a cost-effective, scalable SARS-CoV-2 sample collection kit. Two years later, KRIYA has developed a full spectrum of molecular diagnostic solutions for sample collection for viral and bacterial diseases with track and trace technology.<br /><br />
              KRIYA is deeply committed to developing patient-centric, need-based solutions that transcend existing barriers and bring our global community one step closer to universally equitable healthcare. I, and the amazing team that has chosen to come on this journey with me, will continue to stand by our mission to deliver Good Health for All.
            </h6>
            <h6 className="para" style={{ color: "#000", fontWeight: 600 }}>
              - Anu Moturi
            </h6>
          </Col>
          <Col lg={6} md={12}>
            <img
              src="/images/about/founder01.jpg"
              alt="laptop"
              width="100%"
              className="mt-5 img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Founder;
