import { useState } from "react";
//import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Outlet } from "react-router-dom";

function Navigation() {
  const [show, setShow] = useState(false);
  const showDropdown = () => {
    setShow(!show);
  }
  const hideDropdown = () => {
    setShow(false);
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        style={{ fontFamily: `"Poppins", sans-serif` }}
        className="menu-font"
        sticky="top"
      >
        <div className="menu-container container">
          <Navbar.Brand href="/">
            <img src="/images/logo/logo.png" alt="logo" height="36px" width="auto" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/company">Our Company</Nav.Link>
              <NavDropdown title="Our Products" id="nav-product-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}>
                <NavDropdown.Item href="/products/biosample">Biosample Collection</NavDropdown.Item>
                <NavDropdown.Item href="/products/molecular">Molecular Diagnostics</NavDropdown.Item>
                <NavDropdown.Item href="/products/diabetes">Diabetes Care</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/impact">Our Impact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Outlet />
    </>
  );
}
/**
 <Nav.Link href="/">
                <img src="/images/homepage/burger.png" alt="burger" />
              </Nav.Link> 
 * 
 <Nav.Link href="/impact">Our Impact</Nav.Link>
              <Nav.Link href="/r_d">Our R&D</Nav.Link>
 */
export default Navigation;
