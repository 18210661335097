import { Col, Container, Row } from "react-bootstrap";
/*
<h6 style={{ color: "#ffffff", fontWeight: 600 }}>
              - Mr.William Sen & Mrs. Joan William
            </h6>
*/
function Healthcare() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#1c1c1c",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row className="pt-5 mt-5">
          <Col lg={4} md={12}>
            <img
              src="/images/impact/heart-2.png"
              alt="laptop"
              width="100%"
              className="img-fluid"
            />
          </Col>
          <Col lg={2} md={1} ></Col>
          <Col className="mt-5 text-white pb-5" lg={6} md={12}>
            <h1 className="sub-title pb-1" style={{ fontWeight: 700 }}>
              Making affordable and accessible healthcare a reality
            </h1>


            <h6 className="w-sm-100 w-lg-75 para">
              Our vision{" "}
              <span style={{ color: "#f0b76c", fontWeight: 600 }}>
                – good health for all –
              </span>{" "}
              guides us in our endeavour to develop innovative, cutting-edge, and affordable products that reach underserved markets and vulnerable populations burdened by lifestyle diseases such as diabetes, cardiac and infectious diseases.
              <br />
              <br />
              At Kriya, we are driven by deep science, technical expertise, and frugal engineering.
            </h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Healthcare;
