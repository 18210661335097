import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
/**
 <span style={{ fontWeight: 700 }}>
                    The devices’ most notable features include:
                  </span>
                </p>{" "}
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        100% sensitivity, specificity, and reproducibility
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Eliminating the need for cold chain transport by
                        maintaining specimen integrity until processing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Decreased turnaround time and overall cost by 40% and
                        50%, respectively, through an extraction-free process
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Guaranteed technician safety with minimal risk of
                        infections
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Ergonomically designed saliva collection funnel to
                        prevent reverse flow and ensure patient safety
                      </p>
                    </div>
                  </Col>
                </Row>
 */
function DCollection() {
  const [active, setActive] = useState("first");
  return (
    <>
      <style type="text/css">
        {`
    .div-hover {
     
    }
    .div-hover:hover {
      border: 1px solid #000;
      background-color: #FFFFFF;
      cursor: pointer;
      border-radius: 10px;
    }
    .div-active {
      background-color: #FFFFFF;
      box-shadow : 1px 1px 1px #dfdfdf;
      border-radius: 10px;
    }
    .div-samples {
      border-bottom: 5px #c5ecee solid;
      cursor: pointer;
      
    }
    .div-samples:hover {
      color: #d73b8b;
      border-bottom: none;
    }
    .btn-pink-3 {
      background-color: #d73b8b;
      color: white;
      
    }
    .btn-pink-3:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: white;
    }
    .btn-white-1 {
      background-color: white;
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    .btn-white-1:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    `}
      </style>
      <div className="mobile-padding"
        style={{
          background: "#FAFAFA",
          width: "100%"
        }}
        id="scroll-me-diabetes"
      >
        <Container className="pt-5">
          <h4 className="pt-5 sub-title" style={{ fontWeight: 700 }}>
            The Diabetes Care product range includes:
          </h4>
          <Row className="pt-5 d-flex flex-sm-column flex-lg-row">
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("first");
                }}
                className={
                  active === "first"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/glucose.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p className="p-3 para" style={{ color: "#2b2b2b", fontWeight: 400 }}>
                  KRIOSE<sup>TM</sup> POC Testing
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("second");
                }}
                className={
                  active === "second"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/icon_lancet.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p
                  style={{ color: "#2b2b2b", fontWeight: 400 }}
                  className="p-3 para"
                >
                  KRIVIDA<sup>TM</sup> Capillary Blood Collection
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("third");
                }}
                className={
                  active === "third"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/insulin.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p
                  style={{ color: "#2b2b2b", fontWeight: 400 }}
                  className="p-3 para"
                >
                  KRIVIDA<sup>TM</sup> Insulin Delivery Devices
                </p>
              </div>
            </Col>
          </Row>
          {active === "first" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIOSE<sup>TM</sup> POC Testing
                </h1>
                <p className="pt-4 para">
                  Kriya’s professional blood glucose monitoring products provide accurate blood glucose and HbA1c testing. Designed with patented state-of-the-art technology, our products use electrochemical sensors to deliver reliable results while ensuring minimal oxygen interference in the blood.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        High accuracy and stability due to auto-coding
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Thicker and larger testing strips innovatively designed for safe and easy handling
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Small amounts of blood (0.75 µl) required for testing
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Compatibility with models available in the KRIYACareTM ecosystem
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIOSE<sup>TM</sup> Rightest® GM 700
                    </h6>
                    <img
                      src="/images/biosample/blc_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIOSE<sup>TM</sup> Rightest® GM 700 S
                    </h6>
                    <img
                      src="/images/biosample/blc_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIOSE<sup>TM</sup> Rightest® Strips
                    </h6>
                    <img
                      src="/images/biosample/blc_3.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "second" && (
            <>
              <div>
                <h1
                  className="m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIVIDA<sup>TM</sup> Capillary Blood Collection
                </h1>
                <p className="pt-4 para">
                  Kriya’s lancet products are ideal for easy, safe, and comfortable sampling of capillary blood. Compatible with most of the commonly used devices, our products are ergonomically designed to prioritise patient and technician safety by preventing accidental contamination and needlestick injuries.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Simplified intuitive two-step process for loading and unloading lancets
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Ultra-thin, tri-bevel needle tip for pain-free lancing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Double spring technology to ensure auto-retraction of the lancing tip
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Adjustable tips with five penetration depth settings for varying patient needs
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Lancing Devices
                    </h6>
                    <img
                      src="/images/biosample/ld_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      28G Safety Lancet
                    </h6>
                    <img
                      src="/images/biosample/sl_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      30G Twist Lancet
                    </h6>
                    <img
                      src="/images/biosample/lt_3.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "third" && (
            <>
              <div>
                <h1
                  className="m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIVIDA<sup>TM</sup> Insulin Delivery Devices
                </h1>
                <p className="pt-4 para">
                  Kriya’s insulin-delivery devices are precision-engineered with advanced technology for a pain-free experience.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Compatibility with all leading insulin-delivery pen brands
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Tri-beveled tip for easy and comfortable insertion
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Silicone lubrication for smooth gliding into the skin
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Micro-polishing to prevent painful skin tearing
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Thin-walled medical stainless steel material to enable faster insulin flow rates
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className=" pt-5 mb-5 pb-5">
                <Col />
                <Col sm={12} lg={3} className="text-center pb-3">
                  <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "172px" }} onClick={
                    () => { window.open("/catalogues/IFU_KRIVIDA_INSULIN_PEN_NEEDLE.pdf"); }
                  }><div>IFU - KRIVIDA IP</div></div></div>
                </Col>
                <Col sm={12} lg={3} className="text-center">
                  <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "172px" }} onClick={
                    () => { window.open("/catalogues/IFU_KRIVIDA_INSULIN_SYRINGE.pdf"); }
                  }><div>IFU - KRIVIDA IS</div></div></div>
                </Col>
                <Col />
              </Row>
              <Row className="pb-5">
                <Col />
                <Col sm={12} lg={3} className="text-center pb-3">
                  <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "172px" }} onClick={
                    () => { window.open("/catalogues/IFU_KRIVET_INSULIN_SYRINGES.pdf"); }
                  }><div className="text-center" style={{ width: "100%" }}>IFU - KRIVET IS</div></div></div>
                </Col>
                <Col sm={12} lg={3} className="text-center">
                  <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "172px" }} onClick={
                    () => { window.open("/catalogues/IFU_KRIVET_INSULIN_PEN_NEEDLE.pdf"); }
                  }><div className="text-center" style={{ width: "100%" }}>IFU - KRIVET IP</div></div></div>
                </Col>
                <Col />
              </Row>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIVIDA<sup>TM</sup> Insulin Syringe
                    </h6>
                    <img
                      src="/images/biosample/inpn_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIVIDA<sup>TM</sup> Insulin Pen Needle
                    </h6>
                    <img
                      src="/images/biosample/inpn_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "fourth" && (
            <>
              <div>
                <h1
                  className="w-50 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  Swab and Saliva-Based Sample Collection Devices
                </h1>
                <p className="pt-4 para">
                  Our range of molecular transport media is ideal for easy, safe
                  collection and transport of saliva and oral samples. We also
                  ensure efficient RNA and DNA sequencing and transport for
                  nasopharyngeal samples. All our products are compatible with
                  various instruments, extraction kits, and PCR amplification
                  kits.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The devices’ most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        100% sensitivity, specificity, and reproducibility
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Eliminating the need for cold chain transport by
                        maintaining specimen integrity until processing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Decreased turnaround time and overall cost by 40% and
                        50%, respectively, through an extraction-free process
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Guaranteed technician safety with minimal risk of
                        infections
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Ergonomically designed saliva collection funnel to
                        prevent reverse flow and ensure patient safety
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%"
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2 className="sub-title" >Applications</h2>
                      <h6 className="para" >Collection and transport of specimens for:</h6>
                      <p className="para" >
                        • RNA sequencing for the detection of SARS-CoV-2,
                        influenza, and HPV
                        <br />• DNA sequencing for genomic studies
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "fifth" && (
            <>
              <div style={{ background: "#ffffff" }}>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  Smart Specimen Transportation System
                </h1>
                <p className="pt-4 para">
                  Our Bluetooth-enabled cold-chain bags are ideal for the safe, secure, everyday transport of specimens. They are IoMT integrated, thereby allowing real-time temperature and location monitoring of the samples.
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The system’s most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Multi-chambered design with storage capacity of up to 200 blood samples
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Triple packaging solution to maintain temperature between 2°- 8°C, up to 10 hours
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Shock-proof, leak-proof, and water-resistant
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Ergonomically designed for comfort
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>

            </>
          )}
          <Row className=" pt-5 mb-5 pb-5">
            <Col />
            <Col sm={12} lg={3} className="text-center pb-3">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("/catalogues/Kriya_Catalogue.pdf"); }
              }><div>Download Catalogue</div></div></div>
            </Col>
            <Col sm={12} lg={3} className="text-center">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("mailto:bdadmin@kriyamed.com"); }
              }><div>Request More Details</div></div></div>
            </Col>
            <Col />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DCollection;
