import { Col, Container, Row } from "react-bootstrap";

function Quality() {
  return (
    <div className="mobile-padding"
      style={{
        background: "#FAFAFA",
        width: "100%"
      }}
    >
      <Container className="pt-2 mt-2">
        <Row>
          <Col md={12} sm={12}>
            <h1
              className="pt-5 mt-5 pb-1 sub-title"
              style={{ fontWeight: 700 }}
            >
              Quality healthcare affordable for all
            </h1>
            <p className="para">
              Our growth has been driven by innovation which permeates Kriya and constantly pushes us to breakthrough thinking to create products, solutions and services with impactful value propositions.
              We employ a Big Bang Innovation paradigm to explore and create pioneering solutions that address challenging technical and healthcare delivery problems. In addition, we are constantly looking for opportunities to improve our products and services with a singular focus on increasing affordability and accessibility.
            </p>
          </Col>
        </Row>

        <Row className="pt-5 mt-5 pb-5">
          <Col lg={8} md={12}>
            <img
              src="/images/about/telescope.png"
              alt="laptop"
              width="100%"
              className="img-fluid"
            />
          </Col>
          <Col lg={4} md={12} className="mt-5">
            <h1
              className="pt-5 pb-1 w-sm-100 w-lg-75 sub-title"
              style={{ fontWeight: 700 }}
            >
              Creating a healthy world
            </h1>
            <h6 className="w-sm-100 w-lg-75 para">
              At Kriya, we believe that good health for all can be achieved by making healthcare more equitable regardless of disparities across geographies and economies. This is the cornerstone of Kriya’s vision and drives every decision we make across the company in design, procurement and distribution of our products and solutions to enable accessibility and affordability.
            </h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Quality;
