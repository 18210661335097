import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
/**
 <span style={{ fontWeight: 700 }}>
                    The devices’ most notable features include:
                  </span>
                </p>{" "}
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        100% sensitivity, specificity, and reproducibility
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Eliminating the need for cold chain transport by
                        maintaining specimen integrity until processing
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Decreased turnaround time and overall cost by 40% and
                        50%, respectively, through an extraction-free process
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Guaranteed technician safety with minimal risk of
                        infections
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p style={{ marginLeft: "5px" }}>
                        Ergonomically designed saliva collection funnel to
                        prevent reverse flow and ensure patient safety
                      </p>
                    </div>
                  </Col>
                </Row>
 */
function Collection() {
  const [active, setActive] = useState("first");
  return (
    <>
      <style type="text/css">
        {`
    .div-hover {
     
    }
    .div-hover:hover {
      border: 1px solid #000;
      background-color: #FFFFFF;
      cursor: pointer;
      border-radius: 10px;
    }
    .div-active {
      background-color: #FFFFFF;
      box-shadow : 1px 1px 1px #dfdfdf;
      border-radius: 10px;
    }
    .div-samples {
      border-bottom: 5px #c5ecee solid;
      cursor: pointer;
      
    }
    .div-samples:hover {
      color: #d73b8b;
      border-bottom: none;
    }
    .btn-pink-3 {
      background-color: #d73b8b;
      color: white;
      
    }
    .btn-pink-3:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: white;
    }
    .btn-white-1 {
      background-color: white;
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    .btn-white-1:hover {
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: black;
    }
    `}
      </style>
      <div className="mobile-padding"
        style={{
          background: "#FAFAFA",
          width: "100%"
        }}
        id="scroll-me-biosample"
      >
        <Container className="pt-5" >
          <h4 className="sub-title pt-5" style={{ fontWeight: 700 }}>
            The Biosample Collection product range includes:
          </h4>
          <Row className="pt-5 d-flex flex-sm-column flex-lg-row">
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("first");
                }}
                className={
                  active === "first"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/vacuum.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p className="para" style={{ color: "#2b2b2b", fontWeight: 400 }}>
                  Vacuum and Non-Vacuum Blood Collection Tubes
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("second");
                }}
                className={
                  active === "second"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/urine_test.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p
                  style={{ color: "#2b2b2b", fontWeight: 400 }}
                  className="para"
                >
                  KRIVIDA<sup>TM</sup> Closed Urine Collection System
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("third");
                }}
                className={
                  active === "third"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/blood_test.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p
                  style={{ color: "#2b2b2b", fontWeight: 400 }}
                  className="para"
                >
                  KRIVIDA<sup>TM</sup> Blood Collection Accessories
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("fifth");
                }}
                className={
                  active === "fifth"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/specimen.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p className="para" style={{ color: "#2b2b2b", fontWeight: 400 }}>
                  KRIZAC<sup>TM</sup> Specimen Transport Device
                </p>
              </div>
            </Col>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <div
                typeof="button"
                onClick={() => {
                  setActive("fourth");
                }}
                className={
                  active === "fourth"
                    ? "div-active div-hover position-relative"
                    : "div-hover position-relative"
                }
                style={{ height: "230px" }}
              >
                <img
                  className="mb-4 mt-3"
                  src="/images/biosample/swab_test.svg"
                  alt="love"
                  width={94}
                  height={94}
                />
                <p className="para" style={{ color: "#2b2b2b", fontWeight: 400 }}>
                  KRIVIDA<sup>TM</sup> Sample Collection Swab and Saliva Collection Funnel
                </p>
              </div>
            </Col>
          </Row>
          {active === "first" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  Vacuum and Non-Vacuum Blood Collection Tubes
                </h1>
                <br />
                <br />
                <h6 className="pt-4 para" style={{ fontWeight: 600 }}>KRIVIDA<sup>TM</sup> Vacuum Collection Tubes</h6>
                <p className="pt-4 para">
                  Our range of vacuum blood collection tubes delivers optimum pull force and accurate draw volumes. These products are manufactured to ensure exceptional tube clarity and are resistant to disintegration during probe insertion. The vacuum pressures are uniquely calibrated to allow operation at various altitudes.
                </p>
                <h6 className="pt-4 para" style={{ fontWeight: 600 }}>KLARET<sup>TM</sup> Non-Vacuum Collection Tubes</h6>
                <p className="pt-4 para">
                  Our line of non-vacuum blood collection tubes is designed with special emphasis on the right cap fit to guarantee technician safety. They also ensure clarity to help minimise filling errors
                </p>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%",
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2 className="sub-title">Applications</h2>
                      <h6 className="para">Collection and transport of specimens for:</h6>
                      <p className="para">
                        <ul>
                          <li>Routine haematology investigations including haemoglobin, WBC, platelet count (V+NV)</li>
                          <li>Coagulation profile, encompassing PT, aPTT, bleeding time, clotting time (V)</li>
                          <li>Biochemistry assays such as serum electrolytes, serum lactate, liver function testing (V+NV)</li>
                          <li>Blood glucose and glucose tolerance tests (V+NV)</li>
                          <li>Immunology and serology including HLA phenotyping, rheumatoid factor, FSH, T3, T4 (V+NV)</li>
                          <li>Blood grouping and cross-matching (V+NV)</li>
                          <li>Blood donor screening (V+NV)</li>
                          <li>Acute phase reactants such as ESR, CRP, procalcitonin, fibrinogen (V+NV)</li>
                          <li>Screening and diagnosis of infectious diseases through NS1 antigen, Widal, QBC Malaria testing, HIV ELISA, HIV nucleic acid testing, HBsAg ELISA, anti-HBs antibodies (V+NV)</li>
                          <li>DNA and paternity testing (V)</li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>

              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Blood Collection Tubes
                    </h6>
                    <img
                      src="/images/biosample/vnv_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "second" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIVIDA<sup>TM</sup> Closed Urine Collection System
                </h1>
                <p className="pt-4 para">
                  Our safe urine collection system is ideal for the easy and convenient extraction of urine samples. It uses a special, calibrated vacuum technology to ensure accuracy of specimen collection while maintaining complete sterility of the samples.
                  <br />
                  <br />
                </p>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%"
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2 className="sub-title">Applications</h2>
                      <p className="para">
                        <ul>
                          <li>  	Routine urine analysis such as urine proteins, glucose, electrolytes, specific gravity </li>
                          <li>  	Urine culture and sensitivity </li>
                          <li>  	Urine light microscopy for microorganisms, casts, crystals</li>
                          <li>  	Urine antigen testing for screening of Gonorrhoea, Chlamydia, Legionella, Mycoplasma</li>
                          <li> 	Specialised tests including urine metanephrines, ketone bodies, drug screening, osmolarity, osmolality</li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIVIDA Urine transfer device
                    </h6>
                    <img
                      src="/images/biosample/urine_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      KRIVIDA Urine transfer device
                    </h6>
                    <img
                      src="/images/biosample/urine_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "third" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIVIDA<sup>TM</sup> Blood Collection Accessories
                </h1>
                <p className="pt-4 para">
                  Our products are compatible with Kriya’s complete range of biosample offerings. Kriya’s line of safety-engineered blood collection accessories prevents accidental cross-contamination and needlestick injuries to ensure efficient, hassle-free sample handling.
                  <br />
                  <br />
                </p>
              </div>

              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%"
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2 className="sub-title">Applications</h2>
                      <p className="para">
                        <ul>
                          <li>Routine blood collection and venepuncture</li>
                          <li>Disinfection of sample sites prior to blood collection and point-of-care testing</li>
                          <li>Disposal of medical sharps</li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Alcohol Swab
                    </h6>
                    <img
                      src="/images/biosample/bc_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Blood Collection Needle
                    </h6>
                    <img
                      src="/images/biosample/bc_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Alcohol Pad
                    </h6>
                    <img
                      src="/images/biosample/bc_3.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>


            </>
          )}
          {active === "fourth" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  Oropharyngeal and Nasopharyngeal Swabs
                </h1>
                <p className="pt-4 para">
                  The KRIVIDA<sup>TM</sup> Swabs are specifically intended for the safe collection and handling of viral clinical specimens. Our polyamide flocked Nasopharyngeal and Oropharyngeal swabs are manufactured to enable rich collection of samples.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The devices’ most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        High-density flocked nylon swab
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Customised effortless breakpoint
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Universal Integrated Fit
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  Krivida<sup>TM</sup> Saliva Collection Funnel
                </h1>
                <p className="pt-4 para">
                  The KRIVIDA<sup>TM</sup> Saliva Collection Funnel is a unique unibody screw-on funnel, specially designed for ergonomic fit to cup the patient’s
                  chin — ensuring direct and quick delivery into the tube while enabling maximum collection of saliva. The screw-on funnel prevents backflow/reverse flow of the medium — preventing chemical injuries and ensuring safety for users during self-collection.
                  <br />
                  <br />
                  <span style={{ fontWeight: 400 }}>
                    The devices’ smost notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Ergonomically designed
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Spill proof funnel
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Sterile Funnel to ensure zero cross-contamination.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="mt-5"
                style={{
                  background: "#1c1c1c",
                  width: "100%"
                }}
              >
                {" "}
                <Row>
                  <Col lg={2} />
                  <Col lg={6} className="mt-5 pt-1 mb-5">
                    <div className="text-white app-pad-sub">
                      <h2 className="sub-title">Applications</h2>
                      <h6 className="para">Collection and transport of specimens for:</h6>
                      <p className="para">
                        • Viral clinical specimens from nasal and oral sites of patients for influenza, SARS-CoV-2, RSV, and viruses required to diagnose infectious diseases caused by other viruses.
                      </p>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </div>
              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture1.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture2.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Swab Direct Molecular Transport Medium (Without
                      Extraction)
                    </h6>
                    <img
                      src="/images/biosample/picture3.png"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>

            </>
          )}
          {active === "fifth" && (
            <>
              <div>
                <h1
                  className="w-sm-100 w-lg-75 m-auto pt-5 sub-title"
                  style={{ fontWeight: 400 }}
                >
                  KRIZAC<sup>TM</sup> Specimen Transport Device
                </h1>
                <p className="pt-4 para">
                  Our bluetooth-enabled cold-chain bags are ideal for the safe, secure, everyday transport of specimens. They are IoMT integrated, thereby allowing real-time temperature and location monitoring of the samples.
                  <br />
                  <span className="para" style={{ fontWeight: 400 }}>
                    The system’s most notable features include:
                  </span>
                </p>
                <Row>
                  <Col sm={12} lg={6}>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Multi-chambered design with storage capacity of up to 200 blood samples
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Triple packaging solution to maintain temperature between 2°- 8°C, up to 10 hours
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Shock-proof, leak-proof, and water-resistant
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6}>
                    {" "}
                    <div className="d-flex">
                      <div>
                        <AiFillCheckCircle color="#f1cb95" size={22} />
                      </div>
                      <p className="para" style={{ marginLeft: "5px" }}>
                        Ergonomically designed for comfort
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>


              <Row className="pt-5 mt-5 text-center">
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Home to Lab Smart Specimen Transport System
                    </h6>
                    <img
                      src="/images/biosample/smart_1.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Lab to Lab Smart Specimen Transport System
                    </h6>
                    <img
                      src="/images/biosample/smart_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Smart Specimen Transport System
                    </h6>
                    <img
                      src="/images/biosample/smart_3.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row className=" pt-5 mb-5 pb-5" s>
            <Col />
            <Col sm={12} lg={3} className="text-center pb-3">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("/catalogues/Kriya_Catalogue.pdf"); }
              }><div>Download Catalogue</div></div></div>
            </Col>
            <Col sm={12} lg={3} className="text-center">
              <div className="d-flex align-items-center align-content-center justify-content-center"><div className="banner-button-invert" style={{ width: "222px" }} onClick={
                () => { window.open("mailto:bdadmin@kriyamed.com"); }
              }><div>Request More Details</div></div></div>
            </Col>
            <Col />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Collection;
/*
  <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Blood Collection Tubes
                    </h6>
                    <img
                      src="/images/biosample/vnv_2.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="div-samples">
                    <h6 className="pb-5 para" style={{ fontWeight: 400 }}>
                      Blood Collection Tubes
                    </h6>
                    <img
                      src="/images/biosample/vnv_3.jpg"
                      alt="1"
                      height={150}
                    />
                  </div>
                </Col>
             
*/