import BiosampleBanner from "../components/Biosample/BiosampleBanner";
import Collection from "../components/Biosample/Collection";
import DCollection from "../components/Diabetes/DCollection";
import DiabetesBanner from "../components/Diabetes/DiabetesBanner";
import MCollection from "../components/Molecular/MCollection";
import MolecularBanner from "../components/Molecular/MolecularBanner";
import Footer from "./Footer";
import Navigation from "./Navigation";

export interface ProductProps {
  name: String
}

function Products(props: ProductProps) {
  const ProductCollection = (props.name === "biosample") ? <><BiosampleBanner />
    <Collection /></> : (props.name === "molecular") ? <><MolecularBanner />
      <MCollection /></> : <><DiabetesBanner />
    <DCollection /></>
  return (
    <div>
      <Navigation />
      {ProductCollection}
      <Footer />
    </div>
  );
}
export default Products;
