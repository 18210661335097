import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsPeople } from "react-icons/bs";
function Environment() {
  const [active, setActive] = useState("people");
  return (
    <div className="mobile-padding"
      style={{
        backgroundColor: "white",
        width: "100%"
      }}
    >
      <Container className="pb-5">
        <Row>
          <Col md={12} sm={12}>
            <h1
              className="pt-5 pb-1 sub-title"
              style={{ fontWeight: 700 }}
            >
              Prioritizing Environmental Sustainability
            </h1>
            <p className="para">
              We take sustainability seriously. Healthy people require a healthy environment — one where social determinants of health are optimized in terms of footprint. Kriya’s sustainability promise extends to every aspect of our people and processes.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            md={2}
            sm={2}
            className="para"
          >
            <Button
              variant="none"
              className="mt-2 text-start"
              onClick={() => {
                setActive("people");
              }}
              style={{
                background: `${active === "people" ? "#30122A" : "white"}`,
                color: `${active === "people" ? "white" : "#2b2b2b"}`,
                borderRadius: "5px",
                border: "none",
                outline: "none",
                boxShadow: "none",
                fontWeight: 500,
              }}
            >
              <div style={{ width: "105.92px" }}>
                <BsPeople size={22} style={{ marginRight: "5px" }} />
                PEOPLE
              </div>
            </Button>
            <br />
            <Button
              className="mt-3 mb-4"
              onClick={() => {
                setActive("process");
              }}
              style={{
                background: `${active === "process" ? "#30122A" : "white"}`,
                color: `${active === "process" ? "white" : "#2b2b2b"}`,
                borderRadius: "5px",
                border: "none",
                outline: "none",
                boxShadow: "none",
                fontWeight: 500,
              }}
            >
              <div className="d-flex text-start para" style={{ width: "105.92px" }}>
                <img src={(active === "process") ? "/images/impact/settings.png" : "/images/impact/settings-b.png"}
                  alt="w"
                  width={22}
                  className={(active === "process") ? "process-img-act" : "process-img"} />
                PROCESS
              </div>
            </Button>
          </Col>
          <Col md={6} sm={6} style={{ height: "170px" }} className="mt-1">
            <div style={{ backgroundColor: "#f8f7f7", padding: "1rem" }}>
              {active === "people" && (
                <>
                  <p className="para">
                    We educate and encourage our collaborators to adopt environmentally sustainable practices. We are also committed to making our supply chain more mindful, focusing on responsible sourcing, building, usage, and disposal of our products.
                  </p>
                </>
              )}
              {active === "process" && (
                <>
                  <p className="para">
                    We constantly review our processes to improve value chains and optimize for environmental sustainability.
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Environment;


