import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap"; //Button, 
//import { AiOutlineArrowRight } from "react-icons/ai";

function Products() {
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const handleMouseEnter1 = () => {
    setIsHovering1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovering1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovering2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovering2(false);
  };
  return (
    <>
      <style type="text/css">
        {`
    .btn-black {
      background-color: #202020;
      color: white;
    }
    .btn-black:hover {
      background-color: #d73b8b;
      box-shadow: 0px 0px 60px 6px rgba(215,59,140,0.25);
      color: white;
    }
    
    `}
      </style>

      <Container className="mobile-padding text-left mt-5 mb-5">
        <Row>
          <Col>
            <div className="">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700 }}>
                Our Products
              </h1>
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col className="mt-5 mb-5" lg={6} md={12}>
            <h3
              className="mt-2 sub-title"
              style={{
                fontWeight: 600,
                color: !isHovering ? "#202020" : "#d73b8b",
                cursor: "pointer",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Biosample Collection
            </h3>
            <p className="para">
              Kriya’s biosample collection product range includes the full spectrum of blood collection tubes, safe blood collection needles for safety enabled closed urine sample collection systems for blood and urine sample collection. Our Swab and Saliva based MTM kits are designed for safe extraction-free RT-PCR testing.
            </p>
            <p className="para">
              All our biosample collection devices are fully integrated with Kriya’s One-Click digital platform that enables labs to have complete medical chain of custody of the samples from the point of collection to reporting.
            </p>
            <div><div className="banner-button-invert" style={{ width: "152px" }} onClick={
              () => { window.location.href = "/products/biosample" }
            }><div>Explore More</div></div></div>
          </Col>
          <Col className="mt-3 text-center mb-5" lg={6} md={12}>
            <img
              src="/images/homepage/eprouvette.png"
              alt="e"
              className="text-center img-fluid"
            />
          </Col>
        </Row>

        <Row className="">
          <Col
            className="mt-3 text-center mb-5"
            xs={{ order: 2 }}
            lg={{ order: 1, span: 6 }}
            md={12}
          >
            <img
              className="text-center img-fluid"
              src="/images/homepage/jar.png"
              alt="jar"
            />
          </Col>
          <Col className="mt-5 mb-5" xs={{ order: 1 }} lg={{ order: 2, span: 6 }}>
            <h3
              className="mt-2 sub-title"
              style={{
                fontWeight: 600,
                color: !isHovering1 ? "#202020" : "#d73b8b",
                cursor: "pointer",
              }}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              Molecular Diagnostics
            </h3>
            <p className="para">
              Kriya provides molecular diagnostics solutions and our COVID-19 RT-PCR kit is designed to detect multiple variants of the SARS-COV2 virus including the omicron, deltacron strains providing fast and accurate diagnosis.
            </p>
            <p className="para">
              Kriya’s R&D team is innovating at an accelerated pace to provide next-generation solutions to detect infectious diseases using the RT-PCR testing.
            </p>
            <div><div className="banner-button-invert" style={{ width: "152px" }} onClick={
              () => { window.location.href = "/products/molecular" }
            }><div>Explore More</div></div></div>
          </Col>
        </Row>
        <Row>
          <Col
            className="mt-3 text-center mb-5"
            xs={{ order: 2 }}
            lg={{ order: 2, span: 6 }}
            md={12}
          >
            <img
              className="text-center img-fluid"
              src="/images/homepage/monitor.png"
              alt="monitor"
            />
          </Col>
          <Col className="mt-5 mb-5" xs={{ order: 1 }} lg={{ order: 1, span: 6 }}>
            <h3
              className="mt-2 sub-title"
              style={{
                fontWeight: 600,
                color: !isHovering2 ? "#202020" : "#d73b8b",
                cursor: "pointer",
              }}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              Diabetes Care
            </h3>
            <p className="para">
              Kriya offers advanced POC and POCT care solutions to enable accurate in-home diabetes testing and management. KRIOSE Rightest®, our BGMS solution allows for accurate blood glucose detection and monitoring (patent-pending). Our Clinchem, an advanced HbA1c analyzer measures HbA1c, CRP, glucose and haemoglobin in the blood.
            </p>
            <p className="para">
              We also provide a range of devices for insulin delivery, such as insulin syringes and insulin pen needles that minimise pain, improve patient experience, and enhance diabetes management.
            </p>
            <div><div className="banner-button-invert" style={{ width: "152px" }} onClick={
              () => { window.location.href = "/products/diabetes" }
            }><div>Explore More</div></div></div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Products;
/**
 <img
        src="/images/homepage/stars.png"
        alt="stars"
        className="d-none d-lg-block"
      />
 */