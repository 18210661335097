import {
  //FaFacebookF,
  // FaInstagramSquare,
  FaLinkedinIn,
  // FaTwitter,
  // FaYoutube,
} from "react-icons/fa";

function Footer() {
  return (
    <div
      style={{
        background: "#1c1c1c",
        width: "100%",
      }}
    >
      <div className="footer-container" >
        <div style={{ width: "100%" }}>
          <div className="pb-3 footer-card" >
            <div>
              <div className="mt-3">
                <img src="/images/logo/logo-1.png" alt="logo" width="75px" height="auto" style={{ cursor: "pointer" }} onClick={() => {
                  window.location.href = "/";
                }} />
                <h6 className="mt-1 para" style={{ color: "#ffffff" }}>
                  Good Health For All
                </h6>
              </div>
            </div>
            <div>
              <div className="mt-3 pt-3 para" style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
                <div className="" style={{ height: "30px" }}>
                  <p className="" style={{ color: "#ffffff", fontSize: "11px", fontWeight: 300 }}>
                    General Enquiries:{" "}
                    <a
                      href="mailto:venkatesh.v@kriyamed.com"
                      style={{ color: "#ffffff" }}
                    >
                      venkatesh.v@kriyamed.com
                    </a>
                  </p>
                </div>
                <div
                  style={{ filter: "grayscale(100%)" }}
                  className="pb-3 d-flex flex-row justify-content-between w-75 align-content-start"
                >
                  <p className="" style={{ color: "#ffffff", fontSize: "11px", fontWeight: 300 }}>
                    Product Enquiries:{" "}
                    <a
                      href="mailto:riby@kriyamed.com"
                      style={{ color: "#ffffff" }}
                    >
                      riby@kriyamed.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-3 pt-3 para" style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
                <div className="" style={{ height: "30px" }}>
                  <p className="" style={{ color: "#ffffff", fontSize: "11px", fontWeight: 300 }}>
                    Phone:{" "}
                    <a
                      href="tel:+918754405687"
                      style={{ color: "#ffffff" }}
                    >
                      +91 87544 05687
                    </a>
                  </p>
                </div>
                <div
                  style={{ filter: "grayscale(100%)" }}
                  className="pb-3 d-flex flex-row justify-content-between w-75 align-content-start"
                >
                  <div style={{ padding: "1px", background: "#ffffff" }}>
                    <a href="https://www.linkedin.com/company/kriya-design-technologies-pvt-ltd/" target="_blank" rel="noreferrer">
                      <FaLinkedinIn size={22} style={{ color: "#1c1c1c" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-3 pt-3 para" style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
                <div className="pb-3">
                  <a href="/company" style={{ color: "#ffffff", textDecoration: "none" }}>
                    Our Company
                  </a>
                </div>
                <div className="pb-3">
                  <a href="/impact" style={{ color: "#ffffff", textDecoration: "none" }}>
                    Our Impact
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-3 pt-3 para" style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
                <div className="pb-3 ">
                  <a href="/" style={{ color: "#ffffff", textDecoration: "none" }} target="_blank" rel="noreferrer">
                    Terms of service
                  </a>
                </div>
                <div className="pb-3">
                  <a href="/" style={{ color: "#ffffff", textDecoration: "none" }} target="_blank" rel="noreferrer">
                    Privacy policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-card">
            <div>

            </div>
          </div>
        </div>
      </div>
      <div style={{
        textAlign: 'center', padding: '20px', color: '#fff', fontSize: '10px'
      }}>
        <p>Kriya Medical Technologies Private Limited, B12, SIPCOT Industrial Growth Center, Mathur Post, Oragadam, Chennai-602305, Tamil Nadu, India</p>
      </div>
    </div >
  );
}

export default Footer;

/**
 <div>
              <div className="mt-5 pt-3 para" style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
                <div className="pb-3">
                  <a href="/r_d" style={{ color: "#ffffff", textDecoration: "none" }}>
                    Careers
                  </a>
                </div>
                <div className="pb-3">
                  <a href="/r_d" style={{ color: "#ffffff", textDecoration: "none", display: "none" }}>
                    R&amp;D
                  </a>
                </div>
              </div>
            </div>
 */



/*
<Col sm={12} lg={2} className="mt-5 pt-3" style={{ fontWeight: 700 }}>
            <h6 style={{ color: "#d73b8b", fontWeight: 700 }}>RESOURCES</h6>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Clinical Research
              </a>
            </div>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Blogs
              </a>
            </div>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Academy
              </a>
            </div>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Community
              </a>
            </div>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Webinars
              </a>
            </div>
            <div className="pb-3">
              <a href="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
                Newsletters
              </a>
            </div>
          </Col>



          /ICONS/

              <div>
                <a href="/" target="_blank" rel="noreferrer">
                  <FaFacebookF size={22} />
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noreferrer">
                  <FaInstagramSquare size={22} />
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noreferrer">
                  <FaTwitter size={22} />
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noreferrer">
                  <FaYoutube size={22} />
                </a>
              </div>
          */