import { Container } from "react-bootstrap";

function ImpactBanner() {
  return (
    <Container className="mobile-padding position-relative mb-5">
      <div className="impact-banner-card">
        <div className="mt-5 pt-5 impact-banner-info-layer">
          <h1 className="sub-title" style={{ fontWeight: 300 }}>
            Kriya’s approach to healthcare is driven by Three Promises:
          </h1>
          <h1 style={{ fontWeight: 700 }} className="w-50 banner-title">
            Affordability Sustainability Safety
          </h1>
        </div>

        <div className="text-left impact-banner-img-layer">
          <img
            className="mt-3 img-fluid"
            src="/images/impact/lamb.png"
            alt="hand"
            width="546px"
            height="auto"
          />
        </div>
      </div>
    </Container>
  );
}

export default ImpactBanner;
