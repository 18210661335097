import { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";

export default class Culture extends Component {
  slider: Slider | null | undefined;
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider!.slickNext();
  }
  previous() {
    this.slider!.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 1325,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 845,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <style type="text/css">
          {`
    .btn-purple {
      background-color: #f9f9f9;
      color: white;
    }
    .btn-purple:hover {
      background-color: #c9acc3;
    }
    
    `}
        </style>
        <div className="mobile-padding position-relative pb-5 overflow-hidden" style={{ backgroundColor: "#fafafa" }}>
          <div
            style={{
              background: "#fafafa",
              width: "100%"
            }}
          >
            <div>
              <Container>
                <Row className="pt-5">
                  <Col md={4}>
                    <h2
                      className="pt-2 mt-2 pb-1 text-start w-sm-100 w-lg-75 sub-title"
                      style={{ fontWeight: 700 }}
                    >
                      Our Leadership Team
                    </h2>
                  </Col>
                  <Col md={8}>
                    <p className="w-sm-100 w-lg-75 text-sm-center text-lg-start para">
                      Kriya is a rich and diverse mix of scientists,
                      doctors, engineers, and AI experts – all united by a
                      curiosity to solve complex challenges and improve lives.{" "}
                      <br />
                      <br />
                      We create room for curiosity by nurturing collaborative
                      leadership in every team. We believe that an inspired,
                      empowered, and compassionate leader can help us fulfill
                      our purpose of breaking down barriers and improving
                      healthcare outcomes for all.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Row className="pb-5">
                <Col xs={2} md={1} className="text-end">
                  <Button
                    variant="purple"
                    className="button"
                    onClick={this.previous}
                    style={{
                      borderRadius: "50%",
                      color: "#9e4c8f",
                      marginTop: "9rem",
                    }}
                  >
                    <FaArrowLeft />
                  </Button>
                </Col>

                <Col
                  xs={8}
                  md={10}
                  className="pt-2 mt-2 pb-1 m-sm-auto text-center align-items-center  justify-content-center"
                >
                  <Slider
                    ref={(c) => (this.slider = c)}
                    {...settings}
                    className="mx-auto"
                  >

                    <div>
                      <div className="d-flex  justify-content-center">
                        <img src="/images/about/anu.png" alt="e1" className="img-fluid" />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex  justify-content-center">
                        <img src="/images/about/priya.png" alt="e1" className="img-fluid" />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex  justify-content-center">
                        <img src="/images/about/riby.png" alt="e1" className="img-fluid" />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex  justify-content-center">
                        <img src="/images/about/veera.png" alt="e1" className="img-fluid" />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex  justify-content-center">
                        <img src="/images/about/monish.png" alt="e1" className="img-fluid" />
                      </div>
                    </div>
                  </Slider>
                </Col>

                <Col xs={2} md={1}>
                  <Button
                    variant="purple"
                    className="button"
                    onClick={this.next}
                    style={{
                      borderRadius: "50%",
                      color: "#9e4c8f",
                      marginTop: "9rem",
                    }}
                  >
                    <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
